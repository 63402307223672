<template>
  <div>
    <rc-section title="服务信息">
      <jc-form
        :model="form"
        ref="form"
        :rules="rules"
        label-width="130px"
        class="jc-form-tiny-width"
        label-position="left"
      >
        <jc-form-item prop="appServiceType" label="服务类型：">
          <!-- :disabled="siteIndex === 0" -->
          <jc-radio-group
            :disabled="siteIndex == 0 && (!form.showAppServiceType || !!form.recordSiteId)"
            :readonly="siteIndex == 0 && (!form.showAppServiceType || !!form.recordSiteId)"
            v-model="form.appServiceType"
            @change="handleTypeChange('form')"
          >
            <jc-radio
              v-for="serviceItem in appServiceTypeOptions"
              :key="serviceItem.value"
              :label="serviceItem.value"
              >{{ serviceItem.label }}</jc-radio
            >
          </jc-radio-group>
        </jc-form-item>
        <div
          v-if="
            form.appServiceType === serviceInfoValueMap.SERVICE_INFO_WEB ||
              form.appServiceType === serviceInfoValueMap.SERVICE_INFO_APP
          "
        >
          <jc-form-item
            v-if="!isChangeSiteIp"
            prop="siteName"
            :label="form.appServiceType === serviceInfoValueMap.SERVICE_INFO_WEB ? '网站名称：' : 'APP名称'"
          >
            <jc-input
              v-model.trim="form.siteName"
              type="text"
              placeholder="请输入服务名称"
              :disabled="
                siteIndex == 0 &&
                  (recordType === 6 ||
                    recordType === 7 ||
                    recordType === 8 ||
                    !!(!showAppServiceType && form.appServiceType === serviceInfoValueMap.SERVICE_INFO_APP && siteName))
              "
              @blur="handleSiteNameBlur()"
            ></jc-input>
            <div v-html="websitNameRule" v-show="websitNameRule"></div>
          </jc-form-item>

          <service-app-info :appInfoVo="form.appInfoVo" :appServiceType="form.appServiceType"> </service-app-info>

          <jc-form-item label="关联服务：" prop="serviceValue" v-if="recordType !== RECORD_TYPE_MAP.UPGRADE_RECORD">
            <div style="display: flex">
              <jc-select v-model="form.jcloudServiceType" placeholder="请选择" style="width: 100px" :disabled="true">
                <jc-option
                  v-for="itemOption in SERVICE_TYPE"
                  :key="itemOption.value"
                  :label="itemOption.label"
                  :value="itemOption.value"
                >
                </jc-option>
              </jc-select>
              <jc-select
                v-model="form.serviceValue"
                placeholder="请选择"
                style="width: 240px"
                :disabled="
                  (recordType === RECORD_TYPE_MAP.ADD_RECORD || recordType === RECORD_TYPE_MAP.NO_BODY_ADD_ACCESS) &&
                    infoData.recordSites[siteIndex].serviceValue
                "
                allow-create
                filterable
              >
                <jc-option
                  v-for="itemOption in recordIpList"
                  :key="itemOption.code"
                  :label="itemOption.code"
                  :value="itemOption.code"
                >
                </jc-option>
              </jc-select>
            </div>
            <router-link :to="{ name: 'authCodeApply' }" target="_blank">申请授权码</router-link>
            <a :href="AGREEMENT.authCode" target="_blank" style="margin-left: 15px">授权码说明</a>
          </jc-form-item>
          <jc-form-item
            v-if="!isChangeSiteIp && form.appServiceType === serviceInfoValueMap.SERVICE_INFO_WEB"
            label="网站域名："
            style="margin-bottom: 10px"
            required
          >
            <jc-form-item
              v-for="(item, index) in form.domains"
              :key="item.id"
              :prop="'domains.' + index + '.domain'"
              :rules="rules.domain"
              style="margin-bottom: 16px"
            >
              <!-- 4.主体变更,5:网站变更,6:变更接入,8:无主体新增接入 网站域名不允许修改 -->
              <jc-input
                v-model.trim="item.domain"
                type="text"
                placeholder="请输入域名，格式如：hijcloud.com"
                @blur="handleDomainBlur(form.domains[index].domain, index)"
                :disabled="[4, 6, 8].includes(recordType)"
                :icon="[4, 5].includes(recordType) && index ? 'delete' : ''"
                :on-icon-click="deleteDomain"
                @click="selectNum(index)"
              ></jc-input>
              <p slot="hint">请确保域名完成实名时间≥3个工作日</p>
            </jc-form-item>
            <!-- <jc-button type="text" @click.prevent="addDominBtn" id="nStepSecondDomainAdd" v-if="form.domains.length < 1 || 1" icon="plus">添加域名</jc-button> -->
            <!-- <jc-button type="text" icon="delete"></jc-button> -->
          </jc-form-item>
          <jc-form-item
            v-if="!isChangeSiteIp && form.appServiceType === serviceInfoValueMap.SERVICE_INFO_WEB"
            prop="homeUrl"
            label="网站首页 URL："
          >
            <jc-input
              v-model.trim="form.homeUrl"
              type="text"
              placeholder="请输入网站首页 URL"
              @blur="homeUrlToLower(form.homeUrl)"
              :disabled="true"
            ></jc-input>
          </jc-form-item>
          <jc-form-item
            v-if="form.domainPhylicnum && isShowField('domainPhylicnum')"
            prop="domainPhylicnum"
            label="服务备案号："
          >
            <jc-input
              v-model.trim="form.domainPhylicnum"
              type="text"
              placeholder="服务备案号"
              :disabled="true"
            ></jc-input>
          </jc-form-item>
          <jc-form-item
            v-if="
              !isChangeSiteIp &&
                isShowField('siteTypes') &&
                form.appServiceType === serviceInfoValueMap.SERVICE_INFO_WEB
            "
            prop="siteTypes"
            :label="'主网站服务内容：'"
            :rules="[
              {
                required: true,
                type: 'array',
                message: '请选择服务内容',
                trigger: 'change'
              }
            ]"
          >
            <jc-select
              multiple
              filterable
              v-model="form.siteTypes"
              id="nStepSecondServerContentType"
              style="line-height: 32px"
            >
              <jc-option
                v-for="item in siteTypeOption"
                :key="item.value"
                :value="item.value"
                :label="item.label"
                :disabled="recordType === 6"
                style="margin-left: 0px"
                >{{ item.label }}</jc-option
              >
            </jc-select>
          </jc-form-item>
          <jc-form-item
            v-if="
              !isChangeSiteIp &&
                isShowField('siteTypes') &&
                form.appServiceType === serviceInfoValueMap.SERVICE_INFO_APP
            "
            prop="siteTypesTree"
            :label="'APP服务内容：'"
            :rules="[
              {
                required: true,
                type: 'array',
                message: '请选择服务内容',
                trigger: 'change'
              }
            ]"
          >
            <el-cascader
              style="width: 100%"
              v-model="form.siteTypesTree"
              :options="siteTypesTreeOptions"
              :show-all-levels="false"
              :disabled="recordType === 6"
              :props="{ multiple: true }"
            ></el-cascader>
          </jc-form-item>
          <!-- <div v-if="!isChangeSiteIp && role !== 5"> -->
          <div>
            <service-pre-approval-info v-model="form.preApprovalInfoObject"></service-pre-approval-info>
          </div>
          <jc-form-item
            v-if="!isChangeSiteIp && isShowField('siteLanguage')"
            prop="siteLanguage"
            label="语言类别："
            :rules="[
              {
                required: true,
                type: 'array',
                message: '请选择语言类别',
                trigger: 'change'
              }
            ]"
          >
            <jc-select multiple filterable v-model="form.siteLanguage">
              <jc-option
                v-for="item in languageOptions"
                :key="item.code"
                :value="item.code"
                :label="item.name"
                :disabled="recordType === 6"
                style="margin-right: 15px; margin-left: 0px"
                >{{ item.name }}</jc-option
              >
            </jc-select>
          </jc-form-item>
          <jc-form-item
            v-if="!isChangeSiteIp"
            :label="form.appServiceType === serviceInfoValueMap.SERVICE_INFO_WEB ? '网站备注：' : 'APP备注：'"
          >
            <jc-input v-model.trim="form.remark" type="textarea" :disabled="recordType === 6"></jc-input>
            <div v-html="websiteRemarksRule" v-show="websiteRemarksRule"></div>
          </jc-form-item>
          <!-- </jc-form> -->
        </div>
      </jc-form>
    </rc-section>
    <div class="jc-section" v-if="!isChangeSiteIp && role !== 5">
      <div class="jc-section-header">
        <h3 class="jc-section-title">
          服务负责人
          <jc-checkbox v-model="samePrincipalOwner" :disabled="checkboxDisabled" @change="handleCheckBox"
            >同主体负责人</jc-checkbox
          >
          <small>温馨提示！此负责人需完成后续备案拍照等工作，请根据实际情况填写</small>
        </h3>
      </div>
      <div class="jc-section-content">
        <owner-community
          ref="owner"
          :infoData="ownerData"
          :display="display"
          :ownerPrincipal="ownerPrincipal"
          :website="true"
          labelWidth="120px"
        ></owner-community>
      </div>
    </div>
    <div class="jc-section">
      <div class="jc-section-content">
        <jc-form label-width="120px">
          <jc-form-item>
            <jc-button
              @click.prevent="abandonNew"
              v-if="siteIndex !== 0 && !form.recordSiteId && ![4, 5, 6, 24].includes(recordType)"
              >删除</jc-button
            >
            <jc-button
              @click.prevent="abandonEdit"
              v-if="infoData.recordSites.length > 1 && form.recordSiteId && ![5, 6, 24].includes(recordType)"
              >取消</jc-button
            >
            <jc-button
              @click.prevent="submitWebsite('y')"
              id="stepSecondNewContinue"
              v-if="infoData.recordSites.length < 4 && ![4, 5, 6, 8, 14, 24].includes(recordType)"
              >{{ infoData.recordSites.length === getOnceSitesCount() ? '保存' : '继续添加服务' }}</jc-button
            >
            <jc-button
              @click.prevent="submitWebsite('y')"
              id="stepSecondNewContinue"
              v-if="[4, 5, 14, 24].includes(recordType)"
              >保存</jc-button
            >
            <div v-if="getOnceSitesCount() === 2" class="mt20">
              提示：本次最多提交2个服务，累计最多5个服务
            </div>
          </jc-form-item>
        </jc-form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SERVER_CONTENT_TYPE,
  PRE_APPROVAL_OPTION,
  SERVICE_TYPE,
  AGREEMENT,
  RECORD_TYPE_MAP,
  SERVICE_INFO_VALUE_MAP,
  SERVICE_INFO_TYPE_OPTIONS,
  FREQUENCY_MAP,
  AppInfoVoDefault
  // PreApprovalInfoObjectItemDefault
} from '@/views/service/constant.js'
import { WEBSITE_RULES } from '@/views/service/rules'
import { APP_DOMAIN_RULE } from '@/views/service/rules/validate.rule'
import { queryRule } from '@/service/record-api'
import {
  saveWebsiteInfo,
  // updateStatus,
  newProcess,
  deleteDomain,
  getFrequencyInfo,
  getAuthCodeList,
  checkDomainExist,
  checkServiceExist,
  getPrincipalInfo,
  getPrincipalInfoByPin
} from '@/service/record-api'
import { isNull } from '@/utils'
import profileMixins from '@/mixins/profile.mixins.js'
import apiParams from '@/mixins/upload.mixins.js'
import { nextTick } from '@vue/composition-api'
import ServicePreApprovalInfo from './service.preApprovalInfo.vue'
import ServiceAppInfo from './service.appInfo.vue'

export default {
  props: {
    infoData: Object,
    // 当前服务信息
    siteInfo: Object,
    siteIndex: Number,
    domainInfo: Array
  },
  components: {
    ownerCommunity: () => import('@/views/submit/src/record.information/components/owner.community.vue'),
    ServicePreApprovalInfo,
    ServiceAppInfo
  },
  mixins: [apiParams, profileMixins],
  data() {
    return {
      principalInfo: {},
      websitNameRule: '',
      websiteRemarksRule: '',
      recordNo: this.$route.params.recordNo || '', // 主体id
      domainPhylicnum: this.$route.query.domainPhylicnum,
      appServiceType: this.$route.query.appServiceType || '',
      siteName: this.$route.query.siteName || '',
      display: false,
      checkboxDisabled: false,
      samePrincipalOwner: false,
      form: {
        recordSiteId: this.siteInfo.id || '', // 网站id
        siteName: this.siteInfo.siteName || '', // 网站名称
        domainPhylicnum: this.siteInfo.miitRecordNo || '', // 网站名称
        jcloudServiceType:
          ![1, 3, 6, 7, 8, 9].includes(this.recordType) && this.siteInfo.jcloudServiceType
            ? this.siteInfo.jcloudServiceType
            : 3, // 关联服务
        serviceValue: this.siteInfo.serviceValue || '', // 公网ip、负载均衡
        domains: [{ domain: '', id: null }], // 网站域名
        homeUrl: '',
        remark: this.siteInfo.remark || '', // 备注
        preApprovalOption: this.siteInfo.preApprovalOption || 0, // 前置审批项 兼容老数据
        preApprovalInfoObject:
          this.siteInfo.preApprovalInfoObject ||
          [
            // JSON.parse(JSON.stringify(PreApprovalInfoObjectItemDefault))
          ], // 前置审批项 mp、新
        siteType: this.siteInfo.siteType || 0, // 主网站服务内容
        siteTypes: [],
        siteTypesInfo: this.siteInfo.siteTypesInfo || '',
        siteTypesTree: this.siteInfo.siteTypesInfo ? JSON.parse(this.siteInfo.siteTypesInfo) : [],
        isContinue: 'n',
        preApprovalNo: this.siteInfo.preApprovalNo || '',
        siteLanguage: this.siteInfo.siteLanguage ? this.siteInfo.siteLanguage.split(',') : ['zh_CN'],
        appServiceType: this.siteInfo.appServiceType || '',
        showAppServiceType: true,
        appInfoVo: {
          ...JSON.parse(JSON.stringify(AppInfoVoDefault)),
          siteId: this.siteInfo.id
        }
      },
      ownerData: {}, // 子组件传参
      ownerPrincipal: {}, // 子组件传参
      selectDomain: 0,
      preApproval: PRE_APPROVAL_OPTION,
      rules: {
        ...WEBSITE_RULES.call(this)
      },
      appDomainRule: APP_DOMAIN_RULE.bind(this),
      SERVICE_TYPE,
      AGREEMENT,
      RECORD_TYPE_MAP,
      recordIpList: [], //授权码列表
      /* 新增服务类型相关 */
      // 服务类型选择列表
      serviceInfoValueMap: { ...SERVICE_INFO_VALUE_MAP },
      appServiceTypeOptions: [...SERVICE_INFO_TYPE_OPTIONS]
    }
  },
  watch: {
    infoData: {
      deep: true,
      handler() {
        this.init()
      }
    },
    siteInfo: {
      deep: true,
      handler() {
        this.init()
      }
    },
    'form.domains': {
      deep: true,
      handler() {
        this.form.homeUrl = this.indexUrl
      }
    },
    'form.siteTypesTree': {
      deep: true,
      handler(val, oldVal) {
        if (val.length > 5) {
          this.$message({ type: 'error', message: '服务类目最多可选择5个' })
          this.$nextTick(() => (this.form.siteTypesTree = oldVal))
        }
      }
    }
  },
  computed: {
    // 网站域名和网站首页url同步
    indexUrl() {
      if (this.form.domains.length > 0 && this.form.domains[0].domain) {
        return 'www.' + this.form.domains[0].domain
      } else {
        return ''
      }
    },
    siteTypeOption() {
      if (this.form.appServiceType === 'app') {
        return this.appTypeOptions.map(item => {
          return {
            label: item.localName,
            value: item.gjId
          }
        })
      }
      return SERVER_CONTENT_TYPE
    },
    isChangeSiteIp() {
      // 判断是否为变更接入
      return this.recordNo === 'changeSiteIp'
    }
  },
  async mounted() {
    if (
      this.appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_WEB &&
      this.$route.query.domain &&
      this.siteIndex === 0 &&
      this.form.domains.length === 1 &&
      this.form.domains[0].domain === ''
    ) {
      this.form.domains[0].domain = this.$route.query.domain
    }
    if (
      !this.form.siteName &&
      this.appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP &&
      this.$route.query.siteName &&
      this.siteIndex === 0
    ) {
      this.form.siteName = this.$route.query.siteName
    }

    if (this.appServiceType && !this.form.appServiceType && this.siteIndex === 0) {
      this.form.appServiceType = this.appServiceType
      this.form.showAppServiceType = false
    }
    console.log('showAppServiceType', this.form.showAppServiceType)
    // 处理 siteTypes
    if (this.siteInfo.siteTypes) {
      this.form.siteTypes = this.siteInfo.siteTypes.split(',').map(x => parseInt(x))
    } else {
      if (this.siteInfo.appServiceType === 'web') {
        if (this.role === 5) {
          this.form.siteTypes = [0]
        } else {
          this.form.siteTypes = [1]
        }
      }
    }
  },
  async created() {
    this.init()
    this.getRecordIpList()
    this.getPrincipalInfo()
    this.initAppPt()
  },
  filters: {},
  methods: {
    //获取文本规则
    async getTextRules() {
      try {
        let { data } = await queryRule({
          type: this.role == 5 ? 0 : 1,
          provinceCode: this.principalInfo.provinceCode
        })
        this.websitNameRule = data.find(item => item.fieldCode === 3)
          ? data.find(item => item.fieldCode === 3).content
          : ''
        this.websiteRemarksRule = data.find(item => item.fieldCode === 4)
          ? data.find(item => item.fieldCode === 4).content
          : ''
      } catch (err) {
        console.log(err)
      }
    },
    // 获取主体信息
    async getPrincipalInfo() {
      let recordNo = this.recordNo
      if (['changeSite', 'changeSiteIp'].includes(recordNo)) {
        recordNo = ''
      }

      try {
        let { data } = recordNo ? await getPrincipalInfo({ recordNo }) : await getPrincipalInfoByPin()
        this.principalInfo = data
        this.ownerData = { ...this.ownerData, provinceCode: data.provinceCode }
        this.handleSetDomainInfo()

        await this.getTextRules()
      } catch (err) {
        console.error(err)
      }
    },
    getOnceSitesCount() {
      if (this.principalInfo.whiteFlag) {
        return 3
      }
      return 2
    },
    async getRecordIpList() {
      try {
        const result = await getAuthCodeList({ validFlag: 0 })
        this.recordIpList = result.data
      } catch {
        this.recordIpList = []
      }
    },
    async getFrequencyInfo() {
      try {
        const { ownerIdentityNo, mobile, emergencyCall } = this.$refs.owner?.form || {}
        const { data } = await getFrequencyInfo({
          isPrincipal: false,
          identityNo: ownerIdentityNo,
          mobile,
          emergencyCall
        })
        const duplicateList = Object.entries(data).reduce((res, cur) => {
          const [key, value] = cur
          if (value > 0) {
            res.push(FREQUENCY_MAP[key])
          }
          return res
        }, [])
        if (duplicateList.length) {
          const duplicateTip = duplicateList.join('、')
          const msg = `您输入的${duplicateTip}已在其他账号存在备案，若继续提交有审核失败的风险`
          return this.$jcConfirm(msg, '提示', {
            confirmButtonText: '继续备案',
            cancelButtonText: '返回修改'
          })
            .then(() => {
              return true
            })
            .catch(() => {
              return false
            })
        } else {
          return true
        }
      } catch (err) {
        console.log(err)
      }
    },
    submitWebsite(isContinue) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (
            this.recordType === RECORD_TYPE_MAP.NO_BODY_ADD_WEBSITE ||
            this.recordType === RECORD_TYPE_MAP.BODY_ADD_WEBSITE ||
            this.recordType === RECORD_TYPE_MAP.ADD_RECORD
          ) {
            const { domainSecurity } = await this.getRecordType()
            console.log('getRecordType', domainSecurity)

            if (domainSecurity) {
              this.$message({
                type: 'error',
                message:
                  this.form.appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_WEB
                    ? '此域名已备案成功，请更换未备案域名'
                    : '此APP名称已备案成功，请更换未备案APP名称'
              })
              this.$emit('submitFail')
              return false
            }
          }
          if (this.recordType === RECORD_TYPE_MAP.BODY_ADD_ACCESS) {
            const { domainSecurity, pin } = await this.getRecordType()
            if (!domainSecurity) {
              const msg =
                this.form.appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP
                  ? '此APP名称未备案成功，不符合备案接入'
                  : pin
                  ? `此域名未备案成功，不符合备案接入,且已在京东云${pin}存在信息`
                  : `此域名未备案成功，不符合备案接入`
              this.$message({
                type: 'error',
                message: msg
              })
              this.$emit('submitFail')
              return false
            }
          }
          let params = {}
          try {
            let form = {}
            if (!this.isChangeSiteIp && this.role !== 5) {
              form = await this.$refs.owner.validate()
              const valid = await this.getFrequencyInfo()
              if (!valid) {
                this.$emit('submitFail')
                return
              }
            }
            params = {
              ...form,
              ...this.form,
              recordNo: this.recordNo,
              siteLanguage: this.form.siteLanguage.join(','),
              // siteTypes: this.form.siteTypes.join(','),
              siteTypes:
                this.form.appServiceType === this.serviceInfoValueMap.SERVICE_INFO_APP
                  ? this.form.siteTypesTree.map(x => x[1]).join(',')
                  : this.form.siteTypes.join(','),
              siteTypesInfo: JSON.stringify(this.form.siteTypesTree),
              // appServiceType: this.form.appServiceType.join(','),
              // 第一版默认仅支持 网站 类型
              appServiceType: this.form.appServiceType,
              isPrincipalOwner: this.role === 5 ? 1 : this.samePrincipalOwner ? 1 : 0,
              eleFlag: this.$parent.eleFlagStatus || 0,
              recordSiteId: this.$parent.firstChange ? '' : this.form.recordSiteId,
              miitRecordNo: this.form.domainPhylicnum,
              recordType: this.recordType
            }
            params.domains = this.form.appServiceType == 'web' ? JSON.stringify(params.domains) : ''
            if (params.preApprovalInfoObject && params.preApprovalInfoObject.length > 0) {
              params.preApprovalInfo = JSON.stringify(params.preApprovalInfoObject)
            }
            if (isContinue === 'y') params.isContinue = 'y'
            this.saveWebsite(params)
          } catch (err) {
            console.error(err)
            this.$emit('submitFail')
            try {
              window.__sgm__.custom({
                type: 1,
                code: 'ERROR_SAVE_SERVICE',
                msg: '保存服务错误：' + err.message + '参数：' + JSON.stringify(params)
              })
            } catch (e) {
              console.log(e)
            }

            return false
          }
        } else {
          this.$message({ type: 'error', message: '请完善页面信息' })
          this.$emit('submitFail')
          return false
        }
      })
    },

    async handleSiteNameBlur() {
      if (this.form.appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP) {
        if (
          this.recordType === RECORD_TYPE_MAP.NO_BODY_ADD_WEBSITE ||
          this.recordType === RECORD_TYPE_MAP.BODY_ADD_WEBSITE ||
          this.recordType === RECORD_TYPE_MAP.ADD_RECORD
        ) {
          const { domainSecurity, pin } = await this.getRecordType()
          console.log('domainSecurity', domainSecurity, pin)
          if (domainSecurity) {
            this.$message({
              type: 'error',
              message: '此APP名称已备案成功，请更换未备案APP名称'
            })
            return
          }
        }

        if (
          this.recordType === RECORD_TYPE_MAP.CHANGE_ACCESS ||
          this.recordType === RECORD_TYPE_MAP.BODY_ADD_ACCESS ||
          this.recordType === RECORD_TYPE_MAP.NO_BODY_ADD_ACCESS
        ) {
          const { domainSecurity, pin } = await this.getRecordType()
          console.log('domainSecurity', domainSecurity, pin)
          if (!domainSecurity) {
            this.$message({
              type: 'error',
              message: '此APP名称未备案成功，请更换已备案APP名称'
            })
          }
        }
      }
    },
    async getRecordType(domainIndex = null) {
      // 备案:0：未备案 1：已备案
      // 域名备案情况 : Jd为京东 Security为工信部
      let domain = ''
      if (this.form.appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_WEB) {
        let index = domainIndex === null ? this.form.domains.length - 1 : domainIndex
        domain = this.form.domains[index].domain
        if (!domain) {
          return
        }
      }

      const {
        data: { statusSecurity: domainSecurity, mainPhylicnum: domainMainPhylicnum, servicePhylicnum, pin, websiteName }
      } =
        this.form.appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_WEB
          ? await checkDomainExist({
              domain
            })
          : await checkServiceExist({
              appServiceType: SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP,
              identityNo: this.principalInfo.identityNo || '',
              siteName: this.form.siteName
            })
      return {
        domainSecurity,
        domainMainPhylicnum,
        servicePhylicnum,
        pin,
        websiteName
      }
    },
    async handleSetDomainInfo(domainIndex = null) {
      // 新增接入
      if (this.recordType === 7 || this.recordType === 8) {
        try {
          const { servicePhylicnum, websiteName } = await this.getRecordType(domainIndex)
          this.form.siteName = websiteName || ''
          this.form.domainPhylicnum = servicePhylicnum || ''
        } catch (error) {
          console.log(error)
        }
      }
    },
    async saveWebsite(params = {}) {
      try {
        if (this.form.domainPhylicnum) {
          let result = await getPrincipalInfoByPin()
          let cardPhylicnum = result.data.miitRecordNo
          if (cardPhylicnum && this.form.domainPhylicnum.indexOf(cardPhylicnum) === -1) {
            this.$message({
              type: 'error',
              message: '此域名已备案且与当前帐号下的备案主体不符，请修改'
            })
            return
          }
        }
      } catch (error) {
        console.log(error)
      }
      try {
        if (['changeSite', 'changeSiteIp'].includes(this.recordNo)) {
          if (!this.principalInfo.whiteFlag && this.principalInfo.siteCount >= 5) {
            this.$message({
              type: 'error',
              message: '当前主体最多添加5个服务，不能新增服务！'
            })
            return
          }
          const params = {
            recordType: this.recordType,
            site: {
              ...params,
              ...this.siteInfo,
              domains: JSON.stringify(
                this.form.domains.map(val => {
                  return { domain: val.domain }
                })
              ),
              recordNo: '',
              miitRecordNo: this.siteInfo.miitRecordNo || this.domainPhylicnum || this.form.domainPhylicnum,
              miitRecordPwd: this.siteInfo.miitRecordPwd,
              oldSite: this.$route.query.siteId,
              serviceValue: this.form.serviceValue
            }
          }
          const { data } = await newProcess({ ...params })
          this.$emit('next', 'step3', data.recordNo)
        } else {
          let {
            data: { redirect }
          } = await saveWebsiteInfo(params)
          if (redirect === 'to_step2') {
            this.$emit('saveSuccess')
          } else {
            this.$emit('next', 'step3')
          }
        }
      } catch (e) {
        this.$emit('submitFail')
      }
    },
    // 放弃新增
    abandonNew() {
      this.$emit('abandonNew')
    },
    // 放弃修改
    abandonEdit() {
      this.$emit('abandonEdit')
    },
    // 删除域名
    delBtn(siteindex) {
      this.form.domains.splice(siteindex, 1)
    },
    // 添加域名
    addDominBtn() {
      this.form.domains.push({
        domain: '',
        id: null
      })
    },
    // 勾选同主体负责人 服务负责人信息不可编辑
    handleCheckBox() {
      this.display = this.samePrincipalOwner
    },
    // 域名转化为小写
    handleDomainBlur(val, index) {
      this.form.domains[index].domain = val.toLocaleLowerCase()

      this.handleSetDomainInfo(index)
    },
    // homeUrl转化为小写
    homeUrlToLower(val) {
      this.form.homeUrl = val.toLocaleLowerCase()
    },
    // 删除已存在域名
    async deleteDomain() {
      console.log(this.selectDomain)
      await this.$confirm('确认删除该域名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
      this.$nextTick(async () => {
        await deleteDomain({
          recordNo: this.recordNo,
          siteId: this.form.recordSiteId,
          id: this.form.domains[this.selectDomain].id
        })
        this.form.domains.splice(this.selectDomain, 1)
      })
    },
    selectNum(index) {
      this.selectDomain = index
    },
    initAppPt() {
      if (this.siteInfo.appServiceType === 'app' && this.siteInfo.appInfoVo && this.siteInfo.appInfoVo.appPt) {
        let ptAdded = []
        ;['azPt', 'iosPt', 'hmPt', 'bbPt', 'linuxPt'].forEach(pt => {
          if (
            this.siteInfo.appInfoVo.appPt[pt] &&
            this.siteInfo.appInfoVo.appPt[pt].ptInfo.length > 0 &&
            this.siteInfo.appInfoVo.appPt[pt].ptInfo[0].md5Key
          ) {
            ptAdded.push(pt)
          }
        })
        if (this.siteInfo.appInfoVo.appPt.otherPt) {
          this.siteInfo.appInfoVo.appPt.otherPt.forEach(otherPtItem => {
            if (otherPtItem.ptmc) {
              ptAdded.push('otherPt')
            }
          })
        }
        this.form.appInfoVo.ptAdded = ptAdded
        if (ptAdded.length > 0) {
          this.form.appInfoVo.appPt = this.siteInfo.appInfoVo.appPt
        }
      }

      if (
        this.siteInfo.appInfoVo &&
        this.siteInfo.appInfoVo.useSdkInfoList &&
        this.siteInfo.appInfoVo.useSdkInfoList.length > 0 &&
        this.siteInfo.appInfoVo.useSdkInfoList[0].sdkCsId
      ) {
        this.form.appInfoVo.useSdk = true
        this.form.appInfoVo.useSdkInfoList = this.siteInfo.appInfoVo.useSdkInfoList
      } else {
        this.form.appInfoVo.useSdk = false
      }

      if (this.siteInfo.appInfoVo) {
        this.form.appInfoVo.siteId = this.siteInfo.appInfoVo.siteId || this.siteInfo.id
        this.form.appInfoVo.sdkOfferFlag = this.siteInfo.appInfoVo.sdkOfferFlag
        this.form.appInfoVo.appIcon = this.siteInfo.appInfoVo.appIcon
      }
    },
    // 数据回显
    init() {
      this.ownerPrincipal = this.infoData.recordPrincipal // 一键勾选填写的主体负责人信息
      this.checkboxDisabled = this.recordType === 6
      this.display = this.recordType === 6
      // 非个人回显负责人信息
      if (this.role !== 5) {
        // 仅有第一个服务能够修改负责人信息
        if (this.siteIndex !== 0) {
          this.display = true
          this.checkboxDisabled = true
        }
        // 回显非个人负责人信息
        if (this.infoData.recordSites.length > 0) {
          const siteInfo = this.infoData.recordSites[0]
          if (siteInfo.isPrincipalOwner) {
            this.display = true
            if (this.recordType === 14) {
              this.samePrincipalOwner = false
            } else {
              this.samePrincipalOwner = true
            }
          }
          this.ownerData = {
            ownerName: siteInfo.ownerName || '', // 姓名
            ownerIdentityType: siteInfo.identityType || '', // 证件类型
            ownerIdentityNo: siteInfo.identityNo || '', // 证件号码
            ownerIdentityNoValidityPeriod: siteInfo.identityNoValidityPeriod || '', // 证件有效期
            emergencyCall: siteInfo.emergencyCall || '',
            provinceCode: this.principalInfo.provinceCode,
            mobile: siteInfo.mobile || '',
            email: siteInfo.email || '',
            phone: siteInfo.phone || ''
          }
        }
      }
      if (isNull(this.siteInfo.id)) return
      // 接口中siteInfo 未包含信息 新增服务的时候不读取数据
      this.form.domains = this.domainInfo.map(val => {
        return {
          id: val.id,
          domain: val.domain
        }
      })
      this.form.homeUrl = this.infoData.homeUrl || this.indexUrl
    },
    handleTypeChange(formName) {
      this.form.siteTypes = []
      this.form.siteTypesTree = []

      if (this.form.appServiceType === 'web') {
        if (this.form.siteTypes.length === 0) {
          if (this.role === 5) {
            this.form.siteTypes = [0]
          } else {
            this.form.siteTypes = [1]
          }
        }
      }

      nextTick(() => {
        this.handleClearFormRule(formName, 'siteTypes')
        this.handleClearFormRule(formName, 'siteTypesTree')
      })
      this.handleClearFormRule(formName)
    },
    // 切换类型，表单验证结果
    handleClearFormRule(formName, formItem) {
      this.$refs[formName]?.clearValidate(formItem)
    }
  }
}
</script>

<style scoped>
.jc-radio:nth-child(3n-2) {
  width: 75px;
}
.jc-radio:nth-child(3n-1) {
  width: 110px;
}
.jc-radio:nth-child(3n) {
  width: 10px;
}
</style>
