<template>
  <div>
    <div v-if="appServiceType === 'app'">
      <jc-form-item prop="appInfoVo.appIcon" label="APP图标文件：">
        <jc-upload
          class="avatar-uploader"
          action="/recordcenter/app/manage/uploadPic"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :headers="header"
          with-credentials
        >
          <img v-if="appInfoVo.appIcon" :src="'/recordcenter/app/getAppPic/' + appInfoVo.appIcon" class="avatar" />
          <i v-else class="jc-icon-plus avatar-uploader-icon"></i>
        </jc-upload>
      </jc-form-item>
      <jc-form-item prop="appInfoVo.sdkOfferFlag" label="提供SDK服务:">
        <jc-radio-group v-model="appInfoVo.sdkOfferFlag">
          <jc-radio v-for="sdkItem in sdkOfferFlagOptions" :key="sdkItem.value" :label="sdkItem.value">{{
            sdkItem.label
          }}</jc-radio>
        </jc-radio-group>
        <span slot="hint">APP是否提供SDK服务</span>
      </jc-form-item>
      <jc-form-item prop="appInfoVo.useSdk" label="使用三方SDK:">
        <jc-radio-group v-model="appInfoVo.useSdk">
          <jc-radio v-for="sdkItem in useSdkOptions" :key="sdkItem.value" :label="sdkItem.value">{{
            sdkItem.label
          }}</jc-radio>
        </jc-radio-group>
        <span slot="hint">APP是否使用三方SDK</span>
      </jc-form-item>
      <div v-if="appInfoVo.useSdk">
        <div
          v-for="(useSdkInfo, useSdkInfoIndex) in appInfoVo.useSdkInfoList"
          :key="'useSdkInfoIndex' + useSdkInfoIndex"
        >
          <jc-form-item
            :prop="'appInfoVo.useSdkInfoList.' + useSdkInfoIndex + '.sdkCsId'"
            :label="'SDK服务商' + (useSdkInfoIndex + 1)"
            :rules="[
              {
                required: true,
                type: 'number',
                message: '请选择SDK服务商',
                trigger: 'change'
              }
            ]"
          >
            <jc-select v-model="useSdkInfo.sdkCsId" filterable>
              <jc-option
                v-for="item in sdkCsOptions"
                :key="item.gjId"
                :value="item.gjId"
                :label="item.localName"
                style="margin-right: 15px; margin-left: 0px"
                >{{ item.localName }}</jc-option
              >
            </jc-select>
          </jc-form-item>
          <jc-form-item
            :prop="'appInfoVo.useSdkInfoList.' + useSdkInfoIndex + '.sdkFwlxId'"
            :label="'SDK服务类型' + (useSdkInfoIndex + 1)"
            :rules="{
              required: true,
              type: 'array',
              message: '请选择SDK服务类型',
              trigger: 'change'
            }"
          >
            <jc-select multiple filterable v-model="useSdkInfo.sdkFwlxId">
              <jc-option
                v-for="item in sdkFwlxOptions"
                :key="item.gjId"
                :value="item.gjId"
                :label="item.localName"
                style="margin-right: 15px; margin-left: 0px"
                >{{ item.localName }}</jc-option
              >
            </jc-select>
          </jc-form-item>
          <jc-button
            type="text"
            style="margin: -4px 0 16px 130px;"
            v-if="appInfoVo.useSdkInfoList.length > 1"
            @click.prevent="delUseSdkInfo(useSdkInfoIndex)"
            >删除 {{ 'SDK服务商' + (useSdkInfoIndex + 1) }} 和 {{ 'SDK服务类型' + (useSdkInfoIndex + 1) }}</jc-button
          >
        </div>
        <jc-button type="primary" style="margin: -4px 0 16px 130px;" @click.prevent="addUseSdkInfo()"
          >添加第三方SDK</jc-button
        >
      </div>
    </div>

    <div v-for="(pt, ptIndex) in appInfoVo.ptAdded" :key="'ptIndex' + ptIndex">
      <jc-form-item
        v-if="appServiceType === 'app'"
        :prop="'appInfoVo.ptAdded.' + ptIndex"
        :label="'平台类型' + (ptIndex + 1) + '：'"
        :rules="{
          required: true,
          message: '请选择平台类型',
          trigger: 'change'
        }"
      >
        <jc-select v-model="appInfoVo.ptAdded[ptIndex]" placeholder="请选择" clearable>
          <jc-option
            v-for="ptItem in ptOptions"
            :key="ptItem.value"
            :value="ptItem.value"
            :label="ptItem.label"
            :disabled="
              ptItem.value !== 'otherPt' &&
                appInfoVo.ptAdded.indexOf(ptItem.value) > -1 &&
                appInfoVo.ptAdded.indexOf(ptItem.value) !== ptIndex
            "
          ></jc-option>
        </jc-select>
      </jc-form-item>

      <div style="margin-left: 130px;width: 100%;" v-if="appServiceType === 'app' && pt && pt !== 'otherPt'">
        <div
          v-for="(ptInfoItem, ptInfoItemIndex) in appInfoVo.appPt[pt].ptInfo"
          :key="'ptInfoItemIndex' + ptInfoItemIndex"
        >
          <div style="padding-bottom: 16px;">
            {{ '平台特征值' + (ptInfoItemIndex + 1) }}
          </div>
          <jc-form-item
            v-if="pt === 'azPt'"
            :prop="'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.appBm'"
            label="包名"
            :rules="[
              {
                required: true,
                message: '请输入APP包名',
                trigger: 'blur'
              }
            ]"
          >
            <jc-input
              v-model.trim="ptInfoItem.appBm"
              type="text"
              :maxlength="128"
              placeholder="请输入APP包名"
            ></jc-input>
          </jc-form-item>
          <jc-form-item
            v-if="pt === 'bbPt'"
            :prop="'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.appBmbb'"
            label="包名"
            :rules="[
              {
                required: true,
                message: '请输入APP包名',
                trigger: 'blur'
              }
            ]"
          >
            <jc-input
              v-model.trim="ptInfoItem.appBmbb"
              type="text"
              :maxlength="128"
              placeholder="请输入APP包名"
            ></jc-input>
          </jc-form-item>
          <jc-form-item
            v-if="pt === 'hmPt'"
            :prop="'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.hmBm'"
            label="包名"
            :rules="[
              {
                required: true,
                message: '请输入APP包名',
                trigger: 'blur'
              }
            ]"
          >
            <jc-input
              v-model.trim="ptInfoItem.hmBm"
              type="text"
              :maxlength="128"
              placeholder="请输入APP包名"
            ></jc-input>
          </jc-form-item>

          <jc-form-item
            v-if="pt === 'iosPt'"
            :prop="'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.bundleId'"
            label="Bundle_ID"
            :rules="[
              {
                required: true,
                message: '请输入Bundle_ID',
                trigger: 'blur'
              }
            ]"
          >
            <jc-input
              v-model.trim="ptInfoItem.bundleId"
              type="text"
              :maxlength="128"
              placeholder="请输入Bundle_ID"
            ></jc-input>
          </jc-form-item>

          <jc-form-item
            v-if="pt === 'linuxPt'"
            :prop="'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.processName'"
            label="进程名称"
            :rules="[
              {
                required: true,
                message: '请输入进程名称',
                trigger: 'blur'
              }
            ]"
          >
            <jc-input
              v-model.trim="ptInfoItem.processName"
              type="text"
              :maxlength="128"
              placeholder="请输入进程名称"
            ></jc-input>
          </jc-form-item>

          <jc-form-item
            :prop="'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.key'"
            label="公钥"
            :rules="[
              {
                required: true,
                message: '请输入公钥',
                trigger: 'blur'
              }
            ]"
          >
            <jc-input v-model.trim="ptInfoItem.key" type="text" :maxlength="2048" placeholder="请输入公钥"></jc-input>
          </jc-form-item>
          <jc-form-item
            :prop="'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.md5Key'"
            label="MD5签名"
            :rules="[
              {
                required: true,
                message: '请输入MD5签名',
                trigger: 'blur'
              }
            ]"
          >
            <jc-input
              v-model.trim="ptInfoItem.md5Key"
              type="text"
              :maxlength="256"
              placeholder="请输入MD5签名"
            ></jc-input>
          </jc-form-item>

          <jc-button
            type="text"
            style="margin: -4px 0 16px 130px;"
            v-if="appInfoVo.appPt[pt].ptInfo.length > 1"
            @click.prevent="delPtInfoItem(pt, ptInfoItemIndex)"
            >删除 {{ '平台特征值' + (ptInfoItemIndex + 1) }}</jc-button
          >
        </div>
        <jc-button
          v-if="appInfoVo.appPt[pt].ptInfo.length < 50"
          type="primary"
          style="margin: -4px 0 16px 130px;"
          @click.prevent="addPtInfoItem(pt)"
          >添加平台特征值</jc-button
        >

        <div v-for="(ymXxItem, ymXxItemIndex) in appInfoVo.appPt[pt].ymXx" :key="'ymXxItemIndex' + ymXxItemIndex">
          <jc-form-item
            :prop="`appInfoVo.appPt.${pt}.ymXx.${ymXxItemIndex}.ym`"
            :label="'域名' + (ymXxItemIndex + 1)"
            :rules="[
              {
                required: true,
                message: '请输入域名',
                trigger: 'blur'
              },
              {
                validator: appDomainRule,
                trigger: 'blur'
              }
            ]"
          >
            <jc-input
              v-model="appInfoVo.appPt[pt].ymXx[ymXxItemIndex].ym"
              type="text"
              :maxlength="256"
              placeholder="请输入域名"
            ></jc-input>
          </jc-form-item>
          <jc-button
            type="text"
            style="margin: -4px 0 16px 130px;"
            v-if="appInfoVo.appPt[pt].ymXx.length > 1"
            @click.prevent="delYmXxItem(pt, ymXxItemIndex)"
            >删除 {{ '域名' + (ymXxItemIndex + 1) }}</jc-button
          >
        </div>
        <jc-button
          v-if="appInfoVo.appPt[pt].ymXx.length < 10"
          type="primary"
          style="margin: -4px 0 16px 130px;"
          @click.prevent="addYmXxItem(pt)"
          >添加域名</jc-button
        >
      </div>

      <div style="margin-left: 130px;width: 100%;" v-if="appServiceType === 'app' && pt === 'otherPt'">
        <div
          v-for="(otherPtItem, otherPtItemIndex) in appInfoVo.appPt[pt]"
          :key="'otherPtItemIndex' + otherPtItemIndex"
        >
          <div v-if="otherPtItemIndex === getOtherPtRealIndex(ptIndex)">
            <jc-form-item
              :prop="'appInfoVo.appPt.' + pt + '.' + +otherPtItemIndex + '.ptmc'"
              label="平台名称"
              :rules="[
                {
                  required: true,
                  message: '请输入平台名称',
                  trigger: 'blur'
                }
              ]"
            >
              <jc-input
                v-model.trim="otherPtItem.ptmc"
                type="text"
                :maxlength="128"
                placeholder="请输入平台名称"
              ></jc-input>
            </jc-form-item>

            <div
              v-for="(ptInfoItem, ptInfoItemIndex) in appInfoVo.appPt[pt][otherPtItemIndex].ptInfo"
              :key="'ptInfoItemIndex' + ptInfoItemIndex"
            >
              <div style="padding-bottom: 16px;">
                {{ '平台特征值' + (ptInfoItemIndex + 1) }}
              </div>
              <jc-form-item
                :prop="'appInfoVo.appPt.' + pt + '.' + otherPtItemIndex + '.ptInfo.' + ptInfoItemIndex + '.ptBm'"
                label="包名"
                :rules="[
                  {
                    required: true,
                    message: '请输入APP包名',
                    trigger: 'blur'
                  }
                ]"
              >
                <jc-input
                  v-model.trim="ptInfoItem.ptBm"
                  type="text"
                  :maxlength="128"
                  placeholder="请输入APP包名"
                ></jc-input>
              </jc-form-item>

              <jc-form-item
                :prop="'appInfoVo.appPt.' + pt + '.' + otherPtItemIndex + '.ptInfo.' + ptInfoItemIndex + '.key'"
                label="公钥"
                :rules="[
                  {
                    required: true,
                    message: '请输入公钥',
                    trigger: 'blur'
                  }
                ]"
              >
                <jc-input
                  v-model.trim="ptInfoItem.key"
                  type="text"
                  :maxlength="2048"
                  placeholder="请输入公钥"
                ></jc-input>
              </jc-form-item>
              <jc-form-item
                :prop="'appInfoVo.appPt.' + pt + '.' + otherPtItemIndex + '.ptInfo.' + ptInfoItemIndex + '.md5Key'"
                label="MD5签名"
                :rules="[
                  {
                    required: true,
                    message: '请输入MD5签名',
                    trigger: 'blur'
                  }
                ]"
              >
                <jc-input
                  v-model.trim="ptInfoItem.md5Key"
                  type="text"
                  :maxlength="256"
                  placeholder="请输入MD5签名"
                ></jc-input>
              </jc-form-item>

              <jc-button
                type="text"
                style="margin: -4px 0 16px 130px;"
                v-if="appInfoVo.appPt[pt][otherPtItemIndex].ptInfo.length > 1"
                @click.prevent="delPtInfoItem(pt, ptInfoItemIndex, otherPtItemIndex)"
                >删除 {{ '平台特征值' + (ptInfoItemIndex + 1) }}</jc-button
              >
            </div>
            <jc-button
              v-if="appInfoVo.appPt[pt][otherPtItemIndex].ptInfo.length < 10"
              type="primary"
              style="margin: -4px 0 16px 130px;"
              @click.prevent="addPtInfoItem(pt, otherPtItemIndex)"
              >添加平台特征值</jc-button
            >

            <div
              v-for="(ymXxItem, ymXxItemIndex) in appInfoVo.appPt[pt][otherPtItemIndex].ymXx"
              :key="'ymXxItemIndex' + ymXxItemIndex"
            >
              <jc-form-item
                :prop="'appInfoVo.appPt.' + pt + '.' + otherPtItemIndex + '.ymXx.' + ymXxItemIndex + '.ym'"
                :label="'域名' + (ymXxItemIndex + 1)"
                :rules="[
                  {
                    required: true,
                    message: '请输入域名',
                    trigger: 'blur'
                  },
                  {
                    validator: appDomainRule,
                    trigger: 'blur'
                  }
                ]"
              >
                <jc-input
                  v-model.trim="otherPtItem.ymXx[ymXxItemIndex].ym"
                  type="text"
                  :maxlength="256"
                  placeholder="请输入域名"
                >
                </jc-input>
              </jc-form-item>
              <jc-button
                type="text"
                style="margin: -4px 0 16px 130px;"
                v-if="appInfoVo.appPt[pt][otherPtItemIndex].ymXx.length > 1"
                @click.prevent="delYmXxItem(pt, ymXxItemIndex, otherPtItemIndex)"
                >删除 {{ '域名' + (ymXxItemIndex + 1) }}</jc-button
              >
            </div>
            <jc-button
              v-if="appInfoVo.appPt[pt][otherPtItemIndex].ymXx.length < 10"
              type="primary"
              style="margin: -4px 0 16px 130px;"
              @click.prevent="addYmXxItem(pt, otherPtItemIndex)"
              >添加域名</jc-button
            >
          </div>
        </div>
      </div>

      <jc-button
        type="text"
        style="margin: -4px 0 16px 130px;"
        v-if="appServiceType === 'app' && appInfoVo.ptAdded.length > 1"
        @click.prevent="delPt(ptIndex, getOtherPtRealIndex(ptIndex))"
        >删除 {{ '平台类型' + (ptIndex + 1) + '：' }}</jc-button
      >
    </div>
    <jc-button
      v-if="
        appServiceType === 'app' &&
          (appInfoVo.ptAdded[0] || appInfoVo.ptAdded.length > 1) &&
          appInfoVo.ptAdded.length < 10
      "
      type="primary"
      style="margin: -4px 0 16px 130px;"
      @click.prevent="addPt()"
      >添加平台类型</jc-button
    >
  </div>
</template>

<script>
import { SERVICE_INFO_VALUE_MAP, SERVICE_INFO_TYPE_OPTIONS, PtInfoItemDefault } from '@/views/service/constant.js'
import profileMixins from '@/mixins/profile.mixins.js'
import Cookie from 'js-cookie'

export default {
  props: {
    appServiceType: String,
    appInfoVo: Object
  },
  mixins: [profileMixins],
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      serviceInfoValueMap: { ...SERVICE_INFO_VALUE_MAP },
      appServiceTypeOptions: [...SERVICE_INFO_TYPE_OPTIONS],
      sdkOfferFlagOptions: [
        {
          value: 'YES', // 别问为什么不是boolean，管局就是这么定义
          label: '是'
        },
        {
          value: 'NO',
          label: '否'
        }
      ],
      useSdkOptions: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      ptOptions: [
        { value: 'azPt', label: '安卓' },
        { value: 'iosPt', label: 'iOS' },
        { value: 'hmPt', label: '鸿蒙' },
        { value: 'bbPt', label: '黑莓' },
        { value: 'linuxPt', label: 'linux' },
        { value: 'otherPt', label: '其它平台' }
      ]
    }
  },
  created() {
    // console.log('app info created', this.value)
  },
  computed: {
    // appInfoVo: {
    //   get() {
    //     console.log('get app')
    //     return this.value
    //   },
    //   set(val) {
    //     console.log('set app')
    //     this.$emit('input', val)
    //   }
    // },
    header() {
      return {
        'x-csrf-token': Cookie.get('csrfToken')
      }
    }
  },
  methods: {
    addUseSdkInfo() {
      this.appInfoVo.useSdkInfoList.push({
        sdkCsId: '',
        sdkFwlxId: []
      })
    },
    delUseSdkInfo(useSdkInfoIndex) {
      this.appInfoVo.useSdkInfoList.splice(useSdkInfoIndex, 1)
    },
    addPt() {
      this.appInfoVo.ptAdded.push('')
    },
    async delPt(ptIndex, otherPtIndex) {
      try {
        await this.$confirm('您是否确认删除该平台类型?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        let pt = this.appInfoVo.ptAdded[ptIndex]
        if (pt === 'otherPt') {
          this.appInfoVo.appPt.otherPt[otherPtIndex] = {
            ptmc: '',
            ptInfo: [JSON.parse(JSON.stringify(PtInfoItemDefault))],
            ymXx: [{ ym: '' }]
          }
        } else {
          this.appInfoVo.appPt[pt] = {
            ptInfo: [JSON.parse(JSON.stringify(PtInfoItemDefault))],
            ymXx: [{ ym: '' }]
          }
        }
        this.appInfoVo.ptAdded.splice(ptIndex, 1)
      } catch (error) {
        console.log(error)
      }
    },
    addPtInfoItem(pt, otherPtItemIndex = undefined) {
      if (pt === 'otherPt') {
        this.appInfoVo.appPt[pt][otherPtItemIndex].ptInfo.push(JSON.parse(JSON.stringify(PtInfoItemDefault)))
        return
      }
      this.appInfoVo.appPt[pt].ptInfo.push(JSON.parse(JSON.stringify(PtInfoItemDefault)))
    },
    delPtInfoItem(pt, ptInfoItemIndex, otherPtItemIndex = undefined) {
      if (pt === 'otherPt') {
        this.appInfoVo.appPt[pt][otherPtItemIndex].ptInfo.splice(ptInfoItemIndex, 1)
        return
      }
      this.appInfoVo.appPt[pt].ptInfo.splice(ptInfoItemIndex, 1)
    },
    addYmXxItem(pt, otherPtItemIndex = undefined) {
      if (pt === 'otherPt') {
        this.appInfoVo.appPt[pt][otherPtItemIndex].ymXx.push({ ym: '' })

        return
      }
      this.appInfoVo.appPt[pt].ymXx.push({ ym: '' })
    },
    delYmXxItem(pt, ymXxItemIndex, otherPtItemIndex = undefined) {
      if (pt === 'otherPt') {
        this.appInfoVo.appPt[pt][otherPtItemIndex].ymXx.splice(ymXxItemIndex, 1)
        return
      }
      this.appInfoVo.appPt[pt].ymXx.splice(ymXxItemIndex, 1)
    },
    getOtherPtRealIndex(ptIndex) {
      let real = -1
      this.appInfoVo.ptAdded.forEach((pt, index) => {
        if (pt === 'otherPt' && index <= ptIndex) {
          real = real + 1
        }
      })
      return real
    },
    handleAvatarSuccess(res, file) {
      console.log('file', file)
      this.appInfoVo.appIcon = res.data.url
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style lang="scss" scoped></style>
