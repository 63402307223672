var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.appServiceType === 'app')?_c('div',[_c('jc-form-item',{attrs:{"prop":"appInfoVo.appIcon","label":"APP图标文件："}},[_c('jc-upload',{staticClass:"avatar-uploader",attrs:{"action":"/recordcenter/app/manage/uploadPic","show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload,"headers":_vm.header,"with-credentials":""}},[(_vm.appInfoVo.appIcon)?_c('img',{staticClass:"avatar",attrs:{"src":'/recordcenter/app/getAppPic/' + _vm.appInfoVo.appIcon}}):_c('i',{staticClass:"jc-icon-plus avatar-uploader-icon"})])],1),_c('jc-form-item',{attrs:{"prop":"appInfoVo.sdkOfferFlag","label":"提供SDK服务:"}},[_c('jc-radio-group',{model:{value:(_vm.appInfoVo.sdkOfferFlag),callback:function ($$v) {_vm.$set(_vm.appInfoVo, "sdkOfferFlag", $$v)},expression:"appInfoVo.sdkOfferFlag"}},_vm._l((_vm.sdkOfferFlagOptions),function(sdkItem){return _c('jc-radio',{key:sdkItem.value,attrs:{"label":sdkItem.value}},[_vm._v(_vm._s(sdkItem.label))])}),1),_c('span',{attrs:{"slot":"hint"},slot:"hint"},[_vm._v("APP是否提供SDK服务")])],1),_c('jc-form-item',{attrs:{"prop":"appInfoVo.useSdk","label":"使用三方SDK:"}},[_c('jc-radio-group',{model:{value:(_vm.appInfoVo.useSdk),callback:function ($$v) {_vm.$set(_vm.appInfoVo, "useSdk", $$v)},expression:"appInfoVo.useSdk"}},_vm._l((_vm.useSdkOptions),function(sdkItem){return _c('jc-radio',{key:sdkItem.value,attrs:{"label":sdkItem.value}},[_vm._v(_vm._s(sdkItem.label))])}),1),_c('span',{attrs:{"slot":"hint"},slot:"hint"},[_vm._v("APP是否使用三方SDK")])],1),(_vm.appInfoVo.useSdk)?_c('div',[_vm._l((_vm.appInfoVo.useSdkInfoList),function(useSdkInfo,useSdkInfoIndex){return _c('div',{key:'useSdkInfoIndex' + useSdkInfoIndex},[_c('jc-form-item',{attrs:{"prop":'appInfoVo.useSdkInfoList.' + useSdkInfoIndex + '.sdkCsId',"label":'SDK服务商' + (useSdkInfoIndex + 1),"rules":[
            {
              required: true,
              type: 'number',
              message: '请选择SDK服务商',
              trigger: 'change'
            }
          ]}},[_c('jc-select',{attrs:{"filterable":""},model:{value:(useSdkInfo.sdkCsId),callback:function ($$v) {_vm.$set(useSdkInfo, "sdkCsId", $$v)},expression:"useSdkInfo.sdkCsId"}},_vm._l((_vm.sdkCsOptions),function(item){return _c('jc-option',{key:item.gjId,staticStyle:{"margin-right":"15px","margin-left":"0px"},attrs:{"value":item.gjId,"label":item.localName}},[_vm._v(_vm._s(item.localName))])}),1)],1),_c('jc-form-item',{attrs:{"prop":'appInfoVo.useSdkInfoList.' + useSdkInfoIndex + '.sdkFwlxId',"label":'SDK服务类型' + (useSdkInfoIndex + 1),"rules":{
            required: true,
            type: 'array',
            message: '请选择SDK服务类型',
            trigger: 'change'
          }}},[_c('jc-select',{attrs:{"multiple":"","filterable":""},model:{value:(useSdkInfo.sdkFwlxId),callback:function ($$v) {_vm.$set(useSdkInfo, "sdkFwlxId", $$v)},expression:"useSdkInfo.sdkFwlxId"}},_vm._l((_vm.sdkFwlxOptions),function(item){return _c('jc-option',{key:item.gjId,staticStyle:{"margin-right":"15px","margin-left":"0px"},attrs:{"value":item.gjId,"label":item.localName}},[_vm._v(_vm._s(item.localName))])}),1)],1),(_vm.appInfoVo.useSdkInfoList.length > 1)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"text"},on:{"click":function($event){$event.preventDefault();return _vm.delUseSdkInfo(useSdkInfoIndex)}}},[_vm._v("删除 "+_vm._s('SDK服务商' + (useSdkInfoIndex + 1))+" 和 "+_vm._s('SDK服务类型' + (useSdkInfoIndex + 1)))]):_vm._e()],1)}),_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addUseSdkInfo()}}},[_vm._v("添加第三方SDK")])],2):_vm._e()],1):_vm._e(),_vm._l((_vm.appInfoVo.ptAdded),function(pt,ptIndex){return _c('div',{key:'ptIndex' + ptIndex},[(_vm.appServiceType === 'app')?_c('jc-form-item',{attrs:{"prop":'appInfoVo.ptAdded.' + ptIndex,"label":'平台类型' + (ptIndex + 1) + '：',"rules":{
        required: true,
        message: '请选择平台类型',
        trigger: 'change'
      }}},[_c('jc-select',{attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.appInfoVo.ptAdded[ptIndex]),callback:function ($$v) {_vm.$set(_vm.appInfoVo.ptAdded, ptIndex, $$v)},expression:"appInfoVo.ptAdded[ptIndex]"}},_vm._l((_vm.ptOptions),function(ptItem){return _c('jc-option',{key:ptItem.value,attrs:{"value":ptItem.value,"label":ptItem.label,"disabled":ptItem.value !== 'otherPt' &&
              _vm.appInfoVo.ptAdded.indexOf(ptItem.value) > -1 &&
              _vm.appInfoVo.ptAdded.indexOf(ptItem.value) !== ptIndex}})}),1)],1):_vm._e(),(_vm.appServiceType === 'app' && pt && pt !== 'otherPt')?_c('div',{staticStyle:{"margin-left":"130px","width":"100%"}},[_vm._l((_vm.appInfoVo.appPt[pt].ptInfo),function(ptInfoItem,ptInfoItemIndex){return _c('div',{key:'ptInfoItemIndex' + ptInfoItemIndex},[_c('div',{staticStyle:{"padding-bottom":"16px"}},[_vm._v("\n          "+_vm._s('平台特征值' + (ptInfoItemIndex + 1))+"\n        ")]),(pt === 'azPt')?_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.appBm',"label":"包名","rules":[
            {
              required: true,
              message: '请输入APP包名',
              trigger: 'blur'
            }
          ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":128,"placeholder":"请输入APP包名"},model:{value:(ptInfoItem.appBm),callback:function ($$v) {_vm.$set(ptInfoItem, "appBm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.appBm"}})],1):_vm._e(),(pt === 'bbPt')?_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.appBmbb',"label":"包名","rules":[
            {
              required: true,
              message: '请输入APP包名',
              trigger: 'blur'
            }
          ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":128,"placeholder":"请输入APP包名"},model:{value:(ptInfoItem.appBmbb),callback:function ($$v) {_vm.$set(ptInfoItem, "appBmbb", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.appBmbb"}})],1):_vm._e(),(pt === 'hmPt')?_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.hmBm',"label":"包名","rules":[
            {
              required: true,
              message: '请输入APP包名',
              trigger: 'blur'
            }
          ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":128,"placeholder":"请输入APP包名"},model:{value:(ptInfoItem.hmBm),callback:function ($$v) {_vm.$set(ptInfoItem, "hmBm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.hmBm"}})],1):_vm._e(),(pt === 'iosPt')?_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.bundleId',"label":"Bundle_ID","rules":[
            {
              required: true,
              message: '请输入Bundle_ID',
              trigger: 'blur'
            }
          ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":128,"placeholder":"请输入Bundle_ID"},model:{value:(ptInfoItem.bundleId),callback:function ($$v) {_vm.$set(ptInfoItem, "bundleId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.bundleId"}})],1):_vm._e(),(pt === 'linuxPt')?_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.processName',"label":"进程名称","rules":[
            {
              required: true,
              message: '请输入进程名称',
              trigger: 'blur'
            }
          ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":128,"placeholder":"请输入进程名称"},model:{value:(ptInfoItem.processName),callback:function ($$v) {_vm.$set(ptInfoItem, "processName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.processName"}})],1):_vm._e(),_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.key',"label":"公钥","rules":[
            {
              required: true,
              message: '请输入公钥',
              trigger: 'blur'
            }
          ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":2048,"placeholder":"请输入公钥"},model:{value:(ptInfoItem.key),callback:function ($$v) {_vm.$set(ptInfoItem, "key", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.key"}})],1),_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.ptInfo.' + ptInfoItemIndex + '.md5Key',"label":"MD5签名","rules":[
            {
              required: true,
              message: '请输入MD5签名',
              trigger: 'blur'
            }
          ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":256,"placeholder":"请输入MD5签名"},model:{value:(ptInfoItem.md5Key),callback:function ($$v) {_vm.$set(ptInfoItem, "md5Key", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.md5Key"}})],1),(_vm.appInfoVo.appPt[pt].ptInfo.length > 1)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"text"},on:{"click":function($event){$event.preventDefault();return _vm.delPtInfoItem(pt, ptInfoItemIndex)}}},[_vm._v("删除 "+_vm._s('平台特征值' + (ptInfoItemIndex + 1)))]):_vm._e()],1)}),(_vm.appInfoVo.appPt[pt].ptInfo.length < 50)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addPtInfoItem(pt)}}},[_vm._v("添加平台特征值")]):_vm._e(),_vm._l((_vm.appInfoVo.appPt[pt].ymXx),function(ymXxItem,ymXxItemIndex){return _c('div',{key:'ymXxItemIndex' + ymXxItemIndex},[_c('jc-form-item',{attrs:{"prop":("appInfoVo.appPt." + pt + ".ymXx." + ymXxItemIndex + ".ym"),"label":'域名' + (ymXxItemIndex + 1),"rules":[
            {
              required: true,
              message: '请输入域名',
              trigger: 'blur'
            },
            {
              validator: _vm.appDomainRule,
              trigger: 'blur'
            }
          ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":256,"placeholder":"请输入域名"},model:{value:(_vm.appInfoVo.appPt[pt].ymXx[ymXxItemIndex].ym),callback:function ($$v) {_vm.$set(_vm.appInfoVo.appPt[pt].ymXx[ymXxItemIndex], "ym", $$v)},expression:"appInfoVo.appPt[pt].ymXx[ymXxItemIndex].ym"}})],1),(_vm.appInfoVo.appPt[pt].ymXx.length > 1)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"text"},on:{"click":function($event){$event.preventDefault();return _vm.delYmXxItem(pt, ymXxItemIndex)}}},[_vm._v("删除 "+_vm._s('域名' + (ymXxItemIndex + 1)))]):_vm._e()],1)}),(_vm.appInfoVo.appPt[pt].ymXx.length < 10)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addYmXxItem(pt)}}},[_vm._v("添加域名")]):_vm._e()],2):_vm._e(),(_vm.appServiceType === 'app' && pt === 'otherPt')?_c('div',{staticStyle:{"margin-left":"130px","width":"100%"}},_vm._l((_vm.appInfoVo.appPt[pt]),function(otherPtItem,otherPtItemIndex){return _c('div',{key:'otherPtItemIndex' + otherPtItemIndex},[(otherPtItemIndex === _vm.getOtherPtRealIndex(ptIndex))?_c('div',[_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.' + +otherPtItemIndex + '.ptmc',"label":"平台名称","rules":[
              {
                required: true,
                message: '请输入平台名称',
                trigger: 'blur'
              }
            ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":128,"placeholder":"请输入平台名称"},model:{value:(otherPtItem.ptmc),callback:function ($$v) {_vm.$set(otherPtItem, "ptmc", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"otherPtItem.ptmc"}})],1),_vm._l((_vm.appInfoVo.appPt[pt][otherPtItemIndex].ptInfo),function(ptInfoItem,ptInfoItemIndex){return _c('div',{key:'ptInfoItemIndex' + ptInfoItemIndex},[_c('div',{staticStyle:{"padding-bottom":"16px"}},[_vm._v("\n              "+_vm._s('平台特征值' + (ptInfoItemIndex + 1))+"\n            ")]),_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.' + otherPtItemIndex + '.ptInfo.' + ptInfoItemIndex + '.ptBm',"label":"包名","rules":[
                {
                  required: true,
                  message: '请输入APP包名',
                  trigger: 'blur'
                }
              ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":128,"placeholder":"请输入APP包名"},model:{value:(ptInfoItem.ptBm),callback:function ($$v) {_vm.$set(ptInfoItem, "ptBm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.ptBm"}})],1),_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.' + otherPtItemIndex + '.ptInfo.' + ptInfoItemIndex + '.key',"label":"公钥","rules":[
                {
                  required: true,
                  message: '请输入公钥',
                  trigger: 'blur'
                }
              ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":2048,"placeholder":"请输入公钥"},model:{value:(ptInfoItem.key),callback:function ($$v) {_vm.$set(ptInfoItem, "key", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.key"}})],1),_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.' + otherPtItemIndex + '.ptInfo.' + ptInfoItemIndex + '.md5Key',"label":"MD5签名","rules":[
                {
                  required: true,
                  message: '请输入MD5签名',
                  trigger: 'blur'
                }
              ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":256,"placeholder":"请输入MD5签名"},model:{value:(ptInfoItem.md5Key),callback:function ($$v) {_vm.$set(ptInfoItem, "md5Key", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ptInfoItem.md5Key"}})],1),(_vm.appInfoVo.appPt[pt][otherPtItemIndex].ptInfo.length > 1)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"text"},on:{"click":function($event){$event.preventDefault();return _vm.delPtInfoItem(pt, ptInfoItemIndex, otherPtItemIndex)}}},[_vm._v("删除 "+_vm._s('平台特征值' + (ptInfoItemIndex + 1)))]):_vm._e()],1)}),(_vm.appInfoVo.appPt[pt][otherPtItemIndex].ptInfo.length < 10)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addPtInfoItem(pt, otherPtItemIndex)}}},[_vm._v("添加平台特征值")]):_vm._e(),_vm._l((_vm.appInfoVo.appPt[pt][otherPtItemIndex].ymXx),function(ymXxItem,ymXxItemIndex){return _c('div',{key:'ymXxItemIndex' + ymXxItemIndex},[_c('jc-form-item',{attrs:{"prop":'appInfoVo.appPt.' + pt + '.' + otherPtItemIndex + '.ymXx.' + ymXxItemIndex + '.ym',"label":'域名' + (ymXxItemIndex + 1),"rules":[
                {
                  required: true,
                  message: '请输入域名',
                  trigger: 'blur'
                },
                {
                  validator: _vm.appDomainRule,
                  trigger: 'blur'
                }
              ]}},[_c('jc-input',{attrs:{"type":"text","maxlength":256,"placeholder":"请输入域名"},model:{value:(otherPtItem.ymXx[ymXxItemIndex].ym),callback:function ($$v) {_vm.$set(otherPtItem.ymXx[ymXxItemIndex], "ym", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"otherPtItem.ymXx[ymXxItemIndex].ym"}})],1),(_vm.appInfoVo.appPt[pt][otherPtItemIndex].ymXx.length > 1)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"text"},on:{"click":function($event){$event.preventDefault();return _vm.delYmXxItem(pt, ymXxItemIndex, otherPtItemIndex)}}},[_vm._v("删除 "+_vm._s('域名' + (ymXxItemIndex + 1)))]):_vm._e()],1)}),(_vm.appInfoVo.appPt[pt][otherPtItemIndex].ymXx.length < 10)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addYmXxItem(pt, otherPtItemIndex)}}},[_vm._v("添加域名")]):_vm._e()],2):_vm._e()])}),0):_vm._e(),(_vm.appServiceType === 'app' && _vm.appInfoVo.ptAdded.length > 1)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"text"},on:{"click":function($event){$event.preventDefault();_vm.delPt(ptIndex, _vm.getOtherPtRealIndex(ptIndex))}}},[_vm._v("删除 "+_vm._s('平台类型' + (ptIndex + 1) + '：'))]):_vm._e()],1)}),(
      _vm.appServiceType === 'app' &&
        (_vm.appInfoVo.ptAdded[0] || _vm.appInfoVo.ptAdded.length > 1) &&
        _vm.appInfoVo.ptAdded.length < 10
    )?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addPt()}}},[_vm._v("添加平台类型")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }