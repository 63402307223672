<template>
  <div>
    <div
      v-for="(preApprovalInfoObjectItem, preApprovalInfoObjectIndex) in preApprovalInfoObject"
      :key="preApprovalInfoObjectIndex"
    >
      <jc-form-item
        :prop="'preApprovalInfoObject.' + preApprovalInfoObjectIndex + '.preApprovalOption'"
        :rules="[
          {
            required: true,
            message: '请选择',
            trigger: 'change',
            type: 'number'
          }
        ]"
      >
        <span slot="label">前置审批选项{{ preApprovalInfoObjectIndex + 1 }}：</span>
        <jc-select
          filterable
          v-model="preApprovalInfoObjectItem.preApprovalOption"
          :disabled="disabled"
          placeholder="请选择"
        >
          <jc-option
            v-for="item in preApprovalOptions"
            :key="item.code"
            :value="item.code"
            :label="item.name"
            :disabled="
              preApprovalInfoObject.findIndex(x => x.preApprovalOption === item.code) > -1 &&
                preApprovalInfoObject.findIndex(x => x.preApprovalOption === item.code) !== preApprovalInfoObjectIndex
            "
            >{{ item.name }}</jc-option
          >
        </jc-select>
      </jc-form-item>
      <jc-form-item
        :prop="'preApprovalInfoObject.' + preApprovalInfoObjectIndex + '.preApprovalNo'"
        label="前置审批号"
        :rules="[
          {
            required: true,
            message: '请输入',
            trigger: 'blur'
          }
        ]"
      >
        <jc-input
          v-model.trim="preApprovalInfoObjectItem.preApprovalNo"
          :readonly="disabled"
          type="text"
          :maxlength="128"
          placeholder="请输入"
        ></jc-input>
      </jc-form-item>

      <jc-form-item
        :prop="'preApprovalInfoObject.' + preApprovalInfoObjectIndex + '.preApprovalImages'"
        label="前置审批附件"
        style="width: 750px"
        :rules="{
          required: true,
          type: 'array',
          message: '请添加附件',
          trigger: 'change'
        }"
      >
        <jc-upload
          :readonly="disabled"
          :disabled="disabled"
          action="/record/app/manage/uploadPic"
          list-type="picture-card"
          :file-list="
            preApprovalInfoObjectItem.preApprovalImages.map(x => {
              return { url: '/recordcenter/app/getAppPic/' + x }
            })
          "
          :on-success="
            (response, file, fileList) => {
              handlePreApprovalImagesSuccess(response, file, fileList, preApprovalInfoObjectIndex)
            }
          "
          :on-remove="
            (file, fileList) => {
              handlePreApprovalImagesRemove(file, fileList, preApprovalInfoObjectIndex)
            }
          "
          :on-preview="
            file => {
              handlePreApprovalImagesPreview(file, preApprovalInfoObjectIndex)
            }
          "
          :before-upload="beforeAvatarUpload"
          :headers="header"
          :limit="10"
        >
          <i class="jc-icon-plus avatar-uploader-icon"></i>
        </jc-upload>
      </jc-form-item>

      <jc-button
        :disabled="disabled"
        type="text"
        style="margin: -4px 0 16px 130px;"
        v-if="preApprovalInfoObject.length > 0"
        @click.prevent="delPreApprovalInfoObject(preApprovalInfoObjectIndex)"
        >删除 {{ '前置审批选项' + (preApprovalInfoObjectIndex + 1) }}</jc-button
      >
    </div>

    <jc-form-item v-if="preApprovalInfoObject.length === 0" label="前置审批选项" prop="preApprovalInfoObject">
      <jc-button :disabled="disabled" type="primary" @click.prevent="addPreApprovalInfoObject()"
        >添加前置审批选项</jc-button
      >
    </jc-form-item>
    <jc-button
      v-else
      :disabled="disabled"
      type="primary"
      style="margin: -4px 0 16px 130px;"
      @click.prevent="addPreApprovalInfoObject()"
      >添加前置审批选项</jc-button
    >
    <jc-dialog v-model="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </jc-dialog>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { PreApprovalInfoObjectItemDefault } from '@/views/service/constant.js'
import profileMixins from '@/mixins/profile.mixins.js'

export default {
  props: {
    value: Array
  },
  mixins: [profileMixins],
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  computed: {
    preApprovalInfoObject: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    header() {
      return {
        'x-csrf-token': Cookie.get('csrfToken')
      }
    }
  },
  methods: {
    addPreApprovalInfoObject() {
      this.preApprovalInfoObject.push(JSON.parse(JSON.stringify(PreApprovalInfoObjectItemDefault)))
    },
    delPreApprovalInfoObject(nrlxIndex) {
      this.preApprovalInfoObject.splice(nrlxIndex, 1)
    },
    handlePreApprovalImagesSuccess(res, file, fileList, preApprovalInfoObjectIndex) {
      console.log(res, file, fileList, preApprovalInfoObjectIndex)
      this.preApprovalInfoObject[preApprovalInfoObjectIndex].preApprovalImages.push(res.data.url)
    },
    handlePreApprovalImagesRemove(file, fileList, preApprovalInfoObjectIndex) {
      console.log('file', file)
      let preApprovalImagesIndex = this.preApprovalInfoObject[preApprovalInfoObjectIndex].preApprovalImages.findIndex(
        x => file.url.indexOf(x) > -1
      )
      this.preApprovalInfoObject[preApprovalInfoObjectIndex].preApprovalImages.splice(preApprovalImagesIndex, 1)
    },
    handlePreApprovalImagesPreview(file) {
      console.log('file', file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style lang="scss" scoped></style>
