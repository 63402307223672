var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.preApprovalInfoObject),function(preApprovalInfoObjectItem,preApprovalInfoObjectIndex){return _c('div',{key:preApprovalInfoObjectIndex},[_c('jc-form-item',{attrs:{"prop":'preApprovalInfoObject.' + preApprovalInfoObjectIndex + '.preApprovalOption',"rules":[
        {
          required: true,
          message: '请选择',
          trigger: 'change',
          type: 'number'
        }
      ]}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("前置审批选项"+_vm._s(preApprovalInfoObjectIndex + 1)+"：")]),_c('jc-select',{attrs:{"filterable":"","disabled":_vm.disabled,"placeholder":"请选择"},model:{value:(preApprovalInfoObjectItem.preApprovalOption),callback:function ($$v) {_vm.$set(preApprovalInfoObjectItem, "preApprovalOption", $$v)},expression:"preApprovalInfoObjectItem.preApprovalOption"}},_vm._l((_vm.preApprovalOptions),function(item){return _c('jc-option',{key:item.code,attrs:{"value":item.code,"label":item.name,"disabled":_vm.preApprovalInfoObject.findIndex(function (x) { return x.preApprovalOption === item.code; }) > -1 &&
              _vm.preApprovalInfoObject.findIndex(function (x) { return x.preApprovalOption === item.code; }) !== preApprovalInfoObjectIndex}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('jc-form-item',{attrs:{"prop":'preApprovalInfoObject.' + preApprovalInfoObjectIndex + '.preApprovalNo',"label":"前置审批号","rules":[
        {
          required: true,
          message: '请输入',
          trigger: 'blur'
        }
      ]}},[_c('jc-input',{attrs:{"readonly":_vm.disabled,"type":"text","maxlength":128,"placeholder":"请输入"},model:{value:(preApprovalInfoObjectItem.preApprovalNo),callback:function ($$v) {_vm.$set(preApprovalInfoObjectItem, "preApprovalNo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preApprovalInfoObjectItem.preApprovalNo"}})],1),_c('jc-form-item',{staticStyle:{"width":"750px"},attrs:{"prop":'preApprovalInfoObject.' + preApprovalInfoObjectIndex + '.preApprovalImages',"label":"前置审批附件","rules":{
        required: true,
        type: 'array',
        message: '请添加附件',
        trigger: 'change'
      }}},[_c('jc-upload',{attrs:{"readonly":_vm.disabled,"disabled":_vm.disabled,"action":"/record/app/manage/uploadPic","list-type":"picture-card","file-list":preApprovalInfoObjectItem.preApprovalImages.map(function (x) {
            return { url: '/recordcenter/app/getAppPic/' + x }
          }),"on-success":function (response, file, fileList) {
            _vm.handlePreApprovalImagesSuccess(response, file, fileList, preApprovalInfoObjectIndex)
          },"on-remove":function (file, fileList) {
            _vm.handlePreApprovalImagesRemove(file, fileList, preApprovalInfoObjectIndex)
          },"on-preview":function (file) {
            _vm.handlePreApprovalImagesPreview(file, preApprovalInfoObjectIndex)
          },"before-upload":_vm.beforeAvatarUpload,"headers":_vm.header,"limit":10}},[_c('i',{staticClass:"jc-icon-plus avatar-uploader-icon"})])],1),(_vm.preApprovalInfoObject.length > 0)?_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"disabled":_vm.disabled,"type":"text"},on:{"click":function($event){$event.preventDefault();return _vm.delPreApprovalInfoObject(preApprovalInfoObjectIndex)}}},[_vm._v("删除 "+_vm._s('前置审批选项' + (preApprovalInfoObjectIndex + 1)))]):_vm._e()],1)}),(_vm.preApprovalInfoObject.length === 0)?_c('jc-form-item',{attrs:{"label":"前置审批选项","prop":"preApprovalInfoObject"}},[_c('jc-button',{attrs:{"disabled":_vm.disabled,"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addPreApprovalInfoObject()}}},[_vm._v("添加前置审批选项")])],1):_c('jc-button',{staticStyle:{"margin":"-4px 0 16px 130px"},attrs:{"disabled":_vm.disabled,"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addPreApprovalInfoObject()}}},[_vm._v("添加前置审批选项")]),_c('jc-dialog',{model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }